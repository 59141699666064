import React, { FunctionComponent } from 'react'
import IconLinkedin from 'assets/svg/linkedin.svg'
import IconTwitter from 'assets/svg/twitter.svg'
import { ListContainer, ListItem } from './ListSocials.style'

interface Props {}

const ListSocials: FunctionComponent<Props> = () => {
  return (
    <ListContainer>
      <ListItem>
        <a href="https://www.linkedin.com/company/redfabriq/" target="_blank">
          <IconLinkedin />
        </a>
      </ListItem>
      <ListItem>
        <a href="https://twitter.com/redfabriq" target="_blank">
          <IconTwitter />
        </a>
      </ListItem>
    </ListContainer>
  )
}

export default ListSocials
