import React, { FunctionComponent, useState, MouseEvent } from 'react'
// @ts-ignore
import { useLocalization, LocalizedLink as Link } from 'gatsby-theme-i18n'
import {
  HeaderLang,
  HeaderLangItem,
  HeaderNavContainer,
  HeaderNavFooter,
  HeaderNavItem,
  HeaderNavList,
  HeaderNavContent
} from './HeaderNav.style'
import { PrimaryTitle } from 'styles/Global'
import { IconsName } from 'assets/svg/Icons.enum'
import { HeaderNavData } from './HeaderNav.data'
import { handleLinkMenu } from 'helpers/handleLinkMenu'

// COMPONENTS
import ListSocials from 'components/atoms/ListSocials/ListSocials'
import PrivacyLinks from 'components/atoms/PrivacyLinks/PrivacyLinks'
import Icons from 'components/atoms/Icons/Icons'

interface Props {
  toggleNav: () => void
}

const variants = {
  initial: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
}

const HeaderNav: FunctionComponent<Props> = ({ toggleNav }, props) => {
  const { locale } = useLocalization()
  const [activeLang, setActiveLang] = useState(locale)
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const removeLangUrl = (url: string) => {
    return url.replace('/en', '')
  }
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  }

  return (
    <HeaderNavContainer
      initial="initial"
      animate="visible"
      exit="hidden"
      variants={variants}
    >
      <HeaderNavContent>
        <HeaderNavList>
          {HeaderNavData.map((item, i) => (
            <HeaderNavItem key={i}>
              { item.isExternalLink ? (
                <a href={item.href} target="_blank">
                  <Icons name={IconsName.ARROW_RIGHT} />
                  <PrimaryTitle as="span">{item.name}</PrimaryTitle>
                </a>
              ) : (
                <Link
                  to={item.href}
                  onClick={(e: MouseEvent) =>
                    handleLinkMenu(e, toggleNav, item.href)
                  }
                >
                  <Icons name={IconsName.ARROW_RIGHT} />
                  <PrimaryTitle as="span">{item.name}</PrimaryTitle>
                </Link>
              )}
            </HeaderNavItem>
          ))}
        </HeaderNavList>
        <HeaderLang>
          <HeaderLangItem
            as={Link}
            to={removeLangUrl(location.pathname)}
            language="en"
            active={activeLang === 'en' ? "true" : undefined}
          >
            EN.
          </HeaderLangItem>
          <HeaderLangItem
            as={Link}
            to={removeLangUrl(location.pathname)}
            language="fr"
            active={activeLang === 'fr' ? "true" : undefined}
          >
            FR.
          </HeaderLangItem>
        </HeaderLang>
      </HeaderNavContent>
      <HeaderNavFooter>
        <PrivacyLinks toggleNav={toggleNav} />
        <ListSocials />
      </HeaderNavFooter>
    </HeaderNavContainer>
  )
}

export default HeaderNav
