import styled from 'styled-components'
import { HeaderMenu } from 'components/organisms/Header/Header.style'

export const BurgerMenuContainer = styled.div`
  margin-left: 20px;
`

export const BurgerMenuLine = styled.div<{ isOpen?: boolean }>`
  width: 21px;
  height: 2px;
  background: currentColor;
  display: block;
  transition: 0.5s;
`

export const BurgerMenuTop = styled(BurgerMenuLine)`
  width: ${({ isOpen }) => (isOpen ? '21px' : '10.5px')};
  transform: ${({ isOpen }) =>
    isOpen ? 'rotate(45deg) translate(2px, 1px)' : 'unset'};
  margin-left: auto;
  ${HeaderMenu}:hover & {
    width: 21px;
  }
`

export const BurgerMenuMiddle = styled(BurgerMenuLine)`
  margin: ${({ isOpen }) => (isOpen ? 0 : '4px 0')};
  width: ${({ isOpen }) => (isOpen ? '0' : '21px')};
`

export const BurgerMenuBottom = styled(BurgerMenuLine)`
  width: ${({ isOpen }) => (isOpen ? '21px' : '10.5px')};
  transform: ${({ isOpen }) =>
    isOpen ? 'rotate(-45deg) translate(2px, -1px)' : 'unset'};
  ${HeaderMenu}:hover & {
    width: 21px;
  }
`
