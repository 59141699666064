import styled from 'styled-components'
import { SmallText } from 'styles/Global'

export const PrivacyLinksContainer = styled(SmallText)`
  display: flex;
  align-items: center;
  a {
    span {
      transition: 0.5s;
    }
    &:hover span {
      color: ${({ theme }) => theme.Colors.red};
    }
    &:not(:last-child) {
      margin-right: 1rem;
      position: relative;
      &::after {
        content: '/';
        position: absolute;
        top: 50%;
        right: -0.75rem;
        transform: translateY(-50%);
        pointer-events: none;
        color: inherit;
      }
    }
  }
`
