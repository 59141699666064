import styled from 'styled-components'

export const ListContainer = styled.ul`
  display: flex;
  align-items: center;
`

export const ListItem = styled.li`
  a {
    display: block;
    &:hover svg {
      fill: ${({ theme }) => theme.Colors.red};
    }
  }
  svg {
    transition: 0.5s;
  }
  &:not(:last-child) {
    margin-right: 37px;
  }
`
