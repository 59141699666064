import styled, { css } from "styled-components"
import { motion } from 'framer-motion'
import { SmallText } from 'styles/Global'

export const HeaderNavContainer = styled(motion.nav)<{ isVisible?: boolean }>`
  display: flex;
  background: ${({ theme }) => theme.Colors.white};
  color: ${({ theme }) => theme.Colors.mainBlack};
  min-height: calc(100vh - 95px);
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    justify-content: flex-start;
  }
`

export const HeaderNavContent = styled.div`
  flex: 1;
`;

export const HeaderNavList = styled.ul`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  transition: 0.5s;
  margin-top: 4rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin-top: 3rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    padding-top: 6rem;
    margin-top: 0;
  }
`

export const HeaderNavItem = styled.li`
  position: relative;
  transition: 0.5s;
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
  svg {
    width: 38px;
    height: 38px;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: 0.5s;
  }
  a {
    position: relative;
    transition: 0.5s;
  }
  span {
    position: relative;
    z-index: 1;
    &::after {
      content: '';
      height: 35%;
      width: 0;
      position: absolute;
      bottom: 4px;
      left: 0;
      background: ${({ theme }) => theme.Colors.red};
      transition: 0.5s;
      z-index: -1;
    }
  }
  &:hover {
    svg {
      opacity: 1;
      left: 20px;
    }
    a {
      padding-left: 5rem;
    }
    span {
      padding: 0 10px;
      &::after {
        width: 100%;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    svg {
      width: 30px;
      height: 30px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    svg {
      width: 24px;
      height: 24px;
    }
    a::after {
      height: 30%;
      bottom: 5px;
    }
    &:hover {
      svg {
        left: 0;
      }
      a {
        padding-left: 3rem;
        span {
          padding: 0 5px;
        }
      }
    }
  }
`

export const HeaderLang = styled.div`
  margin-top: 3.5rem;
  font-family: ${({ theme }) => theme.Fonts.redHat.black};
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    margin-top: 5rem;
  }
`

export const HeaderLangItem = styled(SmallText)<{ active: boolean }>`
  display: inline-block;
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  pointer-events: ${({ active }) => (active ? 'none' : 'auto')};
  &:not(:last-child) {
    margin-right: 1rem;
  }
`

export const HeaderNavFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    bottom: 1.5rem;
    ul {
      margin-bottom: 1.5rem;
    }
  }
`
