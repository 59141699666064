import React, { FunctionComponent, MouseEvent } from 'react'
import { Trans } from '@lingui/macro'
// @ts-ignore
import { useLocalization, LocalizedLink as Link } from 'gatsby-theme-i18n'
import { PrivacyLinksContainer } from './PrivacyLinks.style'
import { handleLinkMenu } from 'helpers/handleLinkMenu'

interface Props {
  toggleNav?: () => void
}

const PrivacyLinks: FunctionComponent<Props> = ({ toggleNav }) => {
  const { locale } = useLocalization()
  const handleLink = (e: MouseEvent<HTMLElement>, path: string) => {
    if (toggleNav) {
      handleLinkMenu(e, toggleNav, path)
    }
  }

  return (
    <PrivacyLinksContainer as="div">
      <Link
        to="/legals"
        onClick={(e: MouseEvent<HTMLElement>) => handleLink(e, '/legals')}
      >
        <span>
          <Trans id="footer.link.legal">Mentions légales</Trans>
        </span>
      </Link>
      <Link
        to="/cgu"
        onClick={(e: MouseEvent<HTMLElement>) => handleLink(e, '/cgu')}
      >
        <span>
          <Trans id="footer.link.cgu">CGU</Trans>
        </span>
      </Link>
    </PrivacyLinksContainer>
  )
}

export default PrivacyLinks
