import React, { FunctionComponent } from 'react'

// SVG
import ArrowDown from 'assets/svg/arrowDown.svg'
import ArrowLongDown from 'assets/svg/arrowLongDown.svg'
import ArrowLongLeft from 'assets/svg/arrowLongLeft.svg'
import ArrowLongRight from 'assets/svg/arrowLongRight.svg'
import ArrowRight from 'assets/svg/arrowRight.svg'
import ArrowUp from 'assets/svg/arrowUp.svg'
import BusinessIcon from 'assets/svg/business.svg'
import ChartIcon from 'assets/svg/chart.svg'
import CheckIcon from 'assets/svg/check.svg'
import EditIcon from 'assets/svg/edit.svg'
import FacebookIcon from 'assets/svg/facebook.svg'
import LinkedinIcon from 'assets/svg/linkedin.svg'
import LogoIcon from 'assets/svg/logo.svg'
import StrategyIcon from 'assets/svg/strategy.svg'
import TerminalIcon from 'assets/svg/terminal.svg'
import TwitterIcon from 'assets/svg/twitter.svg'
import { IconsName } from 'assets/svg/Icons.enum'

interface Props {
  name?: IconsName
}

const Icons: FunctionComponent<Props> = ({ name, ...rest }) => {
  switch (name) {
    case IconsName.ARROW_DOWN:
      return <ArrowDown {...rest} />
    case IconsName.ARROW_LONG_DOWN:
      return <ArrowLongDown {...rest} />
    case IconsName.ARROW_LONG_LEFT:
      return <ArrowLongLeft {...rest} />
    case IconsName.ARROW_LONG_RIGHT:
      return <ArrowLongRight {...rest} />
    case IconsName.ARROW_RIGHT:
      return <ArrowRight {...rest} />
    case IconsName.ARROW_UP:
      return <ArrowUp {...rest} />
    case IconsName.BUSINESS:
      return <BusinessIcon {...rest} />
    case IconsName.CHART:
      return <ChartIcon {...rest} />
    case IconsName.CHECK:
      return <CheckIcon {...rest} />
    case IconsName.EDIT:
      return <EditIcon {...rest} />
    case IconsName.FACEBOOK:
      return <FacebookIcon {...rest} />
    case IconsName.LINKEDIN:
      return <LinkedinIcon {...rest} />
    case IconsName.LOGO:
      return <LogoIcon {...rest} />
    case IconsName.TWITTER:
      return <TwitterIcon {...rest} />
    case IconsName.STRATEGY:
      return <StrategyIcon {...rest} />
    case IconsName.TERMINAL:
      return <TerminalIcon {...rest} />
    default:
      return <LogoIcon {...rest} />
  }
}

export default Icons
