import React, {
  ForwardedRef,
  forwardRef,
  FunctionComponent,
  useState,
} from 'react'
import { AnimatePresence } from 'framer-motion'
// @ts-ignore
import { LocalizedLink as Link } from 'gatsby-theme-i18n'
import { useLocation } from '@reach/router'
import {
  StyledHeader,
  HeaderPrimary,
  HeaderLogo,
  HeaderMenu,
  HeaderRight,
} from './Header.style'
import { Container } from 'styles/Global'
import HeaderNav from 'components/molecules/HeaderNav/HeaderNav'
import BurgerMenu from 'components/atoms/BurgerMenu/BurgerMenu'

interface HeaderProps {
  darkMode?: boolean
  ref?: ForwardedRef<HTMLElement>
}

const Header: FunctionComponent<HeaderProps> = forwardRef(
  ({ darkMode }, ref) => {
    const location = useLocation()
    const [navVisible, setNavVisible] = useState(false)
    const onClickMenu = () => {
      const body = document.querySelector('body')
      if (body) {
        !navVisible
          ? body.classList.add('no-scroll')
          : body.classList.remove('no-scroll')
      }

      setNavVisible(!navVisible)
    }
    const onClickLogo = (e: MouseEvent) => {
      if (location.pathname === "/" || location.pathname === "/en/") {
        e.preventDefault();
      }
      navVisible && onClickMenu()
    }

    return (
      <StyledHeader darkMode={darkMode} ref={ref}>
        <Container>
          <HeaderPrimary>
            <Link
              to="/"
              onClick={onClickLogo}
            >
              <HeaderLogo />
            </Link>
            <HeaderRight>
              <HeaderMenu onClick={onClickMenu} isOpen={navVisible}>
                <p>Menu</p>
                <BurgerMenu isOpen={navVisible} />
              </HeaderMenu>
            </HeaderRight>
          </HeaderPrimary>
          <AnimatePresence>
            {navVisible && <HeaderNav toggleNav={onClickMenu} />}
          </AnimatePresence>
        </Container>
      </StyledHeader>
    )
  }
)

export default Header
