import { MouseEvent } from 'react'
import { navigate } from 'gatsby'

export const handleLinkMenu = (
  e: MouseEvent,
  action: () => void,
  currentLocation: string
) => {
  if (window.location.pathname === currentLocation) {
    e.preventDefault()
    action()
  } else {
    navigate(
      window.location.pathname.includes('en')
        ? `/en${currentLocation}`
        : currentLocation
    )
  }
}
