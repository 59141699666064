import styled, { css } from 'styled-components'
import { HeaderNavContainer } from 'components/molecules/HeaderNav/HeaderNav.style'
import Icons from 'components/atoms/Icons/Icons'

export const StyledHeader = styled.header<{ darkMode?: boolean; ref: any }>`
  position: fixed;
  top: 0;
  left: 0;
  background: ${({ theme, darkMode }) =>
    darkMode ? theme.Colors.mainBlack : theme.Colors.white};
  color: ${({ theme, darkMode }) =>
    darkMode ? theme.Colors.white : theme.Colors.mainBlack};
  width: 100%;
  z-index: 10;
  transition: 0.5s;
  ${props =>
    props.darkMode &&
    css`
      ${HeaderNavContainer} {
        background: ${({ theme }) => theme.Colors.mainBlack};
        color: ${({ theme }) => theme.Colors.white};
      }
    `}
`

export const HeaderPrimary = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.25rem 0;
`

export const HeaderLogo = styled(Icons)``

export const HeaderMenu: any = styled.div<{ isOpen?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: ${({ theme }) => theme.Fonts.redHat.black};
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    p {
      display: none;
    }
  }
`
export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  a {
    margin-right: 2rem;
    font-family: ${ ({ theme }) => theme.Fonts.redHat.bold };
    transition: 0.5s;
    &:hover {
      color: ${ ({ theme }) => theme.Colors.red };
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    a {
      margin-right: 0;
      font-size: 14px;
    }
  }
`;