import React, { FunctionComponent } from 'react'
import {
  BurgerMenuBottom,
  BurgerMenuContainer,
  BurgerMenuMiddle,
  BurgerMenuTop,
} from './BurgerMenu.style'

interface Props {
  isOpen?: boolean
}

const BurgerMenu: FunctionComponent<Props> = ({ isOpen }) => {
  return (
    <BurgerMenuContainer>
      <BurgerMenuTop isOpen={isOpen} />
      <BurgerMenuMiddle isOpen={isOpen} />
      <BurgerMenuBottom isOpen={isOpen} />
    </BurgerMenuContainer>
  )
}

export default BurgerMenu
