export enum IconsName {
  ARROW_DOWN = 'arrow_down',
  ARROW_UP = 'arrow_up',
  ARROW_RIGHT = 'arrow_right',
  ARROW_LONG_DOWN = 'arrow_long_down',
  ARROW_LONG_RIGHT = 'arrow_long_right',
  ARROW_LONG_LEFT = 'arrow_long_left',
  BUSINESS = 'business',
  CHART = 'chart',
  CHECK = 'check',
  EDIT = 'edit',
  FACEBOOK = 'facebook',
  LINKEDIN = 'linkedin',
  LOGO = 'logo',
  STRATEGY = 'strategy',
  TERMINAL = 'terminal',
  TWITTER = 'twitter',
}
